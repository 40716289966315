import Layout from "../components/layout"
import SEO from "../components/seo"
import { LaTeX } from "latex2react"
import React, { useState } from "react"

import { UnControlled as CodeMirror } from "react-codemirror2"
import "codemirror/lib/codemirror.css"
import "codemirror/theme/material.css"
import "codemirror/mode/stex/stex"

import ex1 from "../snippets/1"
import ex2 from "../snippets/2"

const examples = [ex1, ex2]

const mod = (n, m) => ((n % m) + m) % m

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const Sandbox = () => {
  const [tex, setTex] = useState("")
  const [count, setCount] = useState(0)
  const loadRandom = event => {
    event.preventDefault()
    setCount(getRandomInt(0, examples.length))
    setTex(examples[count])
  }

  const isSSR = typeof window === "undefined"

  if (isSSR) {
    return <Layout>SSR</Layout>
  }

  return (
    <Layout>
      <SEO title="About Mathapedia" />
      <div style={{ display: "none" }}>
        <LaTeX content="$$" />
      </div>
      <p>
        type $\LaTeX$ + HTML in the box below. Read more info here:{" "}
        <a
          href="http://docs.mathjax.org/en/latest/tex.html"
          data-bypass="true"
          target="mathjax"
        >
          MathJax Docs
        </a>
      </p>

      {/* <textarea className="latex-content"></textarea> */}

      <CodeMirror
        value={tex}
        options={{
          mode: "text/x-stex",
          theme: "material",
          lineNumbers: true,
        }}
        onChange={(editor, data, value) => {
          setTex(value)
        }}
      />

      <hr />

      <button className="save-sketch btn btn-mini btn-primary disabled">
        Save Sketch
      </button>
      <button
        className="random-sketch btn btn-mini btn-primary"
        onClick={loadRandom}
      >
        View Random Sketch
      </button>

      {/* Progress bar for later when you get saving to work */}
      <div className="progress progress-info progress-striped">
        <div className="bar" style={{ width: "0%" }}></div>
      </div>

      {tex && <LaTeX content={tex} />}
      {/* {tex && <pre>{tex}</pre>} */}
    </Layout>
  )
}

export default Sandbox
